import http from '@/utils/request'
import Qs from 'qs'
export default {
    //获取疾病列表
    diseasList: (data) => http.get('/mini/recruit/ill/list', { params: data }),
    //获取关系枚举和体力枚举
    enumsList: (data) => http.get('/mini/recruit/enums/list', { params: data }),
    //获取地区列表
    region: (data) => http.get('/wx/system/region/getChildren', { params: data }),
    //获取医院列表
    hospital: (data) => http.get('/wx/system/hospital/list', { params: data }),
    //获取患者-列表
    patientList: (data) => http.get('/mini/recruit/inquiry/findPage', { params: data }),
    //获取我报名的项目列表
    joinList: (data) => http.get('/mini/recruit/apply/list', { params: data }),
    //获取我报名的项目列表
    favoriteList: (data) => http.get('/mini/recruit/project/favorite/list', { params: data }),
    //开始匹配_询问问题_下一页
    getQuestion: (data) => http.get('/wx/recruit/question/option/nextQuestion', { params: data }),
    //开始匹配_询问问题_上一页
    getPreQuestion: (data) => http.get('/wx/recruit/question/option/preQuestion', { params: data }),
    //开始匹配_保存问题
    saveAnswer: (data) => http.get('/wx/recruit/match/saveAnswer', { params: data }),
    // 开始匹配_提交问题
    submitAnswer: (data) => http.post('/wx/recruit/match/submitAnswer', Qs.stringify(data)),
    //开始匹配_保存问题
    modifyQuestion: (data) => http.get('/wx/recruit/match/modifyQuestion', { params: data }),
    //获取不同癌种的分期表
    stagesList: (data) => http.get('/mini/recruit/ill/stages/list', { params: data }),
    //添加编辑患者
    save: (data) => http.post('/mini/recruit/inquiry/save', Qs.stringify(data)),
    //获取患者详情
    get: (data) => http.get('/mini/recruit/inquiry/get', { params: data }),
    // 开始匹配_判断会员是否有患者
    hasInquiry: (data) => http.get('/wx/recruit/img/upload/hasInquiry', { params: data }),
    //  患者-列表
    inquiryList: (data) => http.get('/mini/recruit/inquiry/list', { params: data }),
    //删除患者
    deleteInquiry: (data) => http.get('/mini/recruit/inquiry/delete', { params: data }),
    //获取患者的试验匹配的地区
    matchArea: (data) => http.get('/wx/recruit/match/matchArea', { params: data }),
    //获取患者匹配项目 有患者id
    matchMember: (data) => http.get('/wx/recruit/match/matchMember', { params: data }),
    // 开始匹配_绑定病患
    confirmInquiry: (data) => http.post('/wx/recruit/img/upload/confirmInquiry', Qs.stringify(data)),
    // 开始匹配_同意上传病历
    agreeUpload: () => http.post('/wx/recruit/img/upload/agreeUpload'),
}