<template>
	<div class="item">
		<div class="field">
			<div class="title">{{title}}</div>

			<div class="group">
				<div class="status" :style="'background:'+colors[index]">{{name}}</div>
				<!-- <div class="time">截止日期 {{time}}</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//标题
			title: {
				type: String,
				default: ''
			},
			//标题
			time: {
				type: String,
				default: ''
			},
			index: {
				type: Number,
				default: 0,
			},
			name: {
				type: String,
				default: ''
			},
		},
		data() {
			return {
				colors: ['#FAC400', '#0050A2', '#D0021B'],
				status: ['招募中', '未开始', '招募结束'],
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.item{
		padding: 0 10px 15px;
	}
	.field {
		width: 100%;
		height: auto;
		background: rgba(255, 255, 255, 1);
		box-shadow: 0px -2px 5px 2px rgba(162, 162, 162, 0.5);
		border-radius: 5px;
		margin-top: 15px;
		padding: 15px;
		box-sizing: border-box;

		.title {
			width: 100%;
			height: auto;
			font-size: 16px;
			font-weight: 600;
			color: rgba(49, 49, 52, 1);
			box-sizing: border-box;
		}

		.group {
			display: flex;
			align-items: center;
			margin-top: 15px;

			.time {
				width: auto;
				height: auto;
				font-size: 14px;
				font-weight: 400;
				color: rgba(172, 171, 173, 1);
				line-height: 14px;
				margin-left: 15px;
			}

			.status {
				width: auto;
				height: auto;
				font-size: 14px;
				font-weight: 500;
				color: rgba(255, 255, 255, 1);
				padding: 3px 6px;
				border-radius: 3px;
			}
		}


	}
</style>
