<template>
	<div class="page">
		<van-pull-refresh v-model="isLoading" @refresh="onRefresh">
			<van-list v-model="loading" :finished="finished" :finished-text="finishtext" @load="onLoad">
				<template v-for="(item, i) in list">
					<item :key="i" :title="item.projectName" :name="item.statusName" :time="item.createTime" :index="item.status" @click.native="jump(item)"></item>
				</template>
		
			</van-list>
		</van-pull-refresh>
		
	</div>
</template>

<script>
	import {
		PullRefresh,
		List
	} from 'vant';
	import patientApi from '@/api/patient/patientApi.js';
	import item from '@/components/mine/favItem';
	export default{
		components:{
				[PullRefresh.name]: PullRefresh,
				[List.name]: List,
				item,
			},
			data() {
				return {
					pageCount: 0,
					pageNo: 0,
					isShowProject: true,
					list: [],
					loading: false,
					finished: false,
					finishtext: '没有更多了',
					isLoading: false, //控制下拉刷新的加载动画
				}
			},
			methods: {
				jump(item){
					this.$router.push({
						path:'/clinicalTrialDeail',
						query:{
							id:item.id,
						}
					})
				},
				onLoad() {
					this.pageNo++;
					if (this.pageNo > 1 && this.pageNo > this.pageCount) {
						this.loading = false;
						this.finished = true;
						return;
					}
					this.getList();
			
				},
				//下拉刷新
				onRefresh() {
					Object.assign(this, {
						pageCount: 0,
						pageNo: 1,
						list: []
					})
					this.getList(); //加载数据
				},
				//获取列表
				getList() {
					patientApi.favoriteList({
						pageNo: this.pageNo
					}).then(rs => {
						this.list=this.list.concat(rs.data.list);
						//console.log(rs.data);
						this.finishLoad(rs)
					}).catch(() => {
						// this.finishLoad();
					});
				},
				
				finishLoad(rs) {
					this.isLoading = false;
					this.loading = false;
					if(!rs){
						this.finished = true;
						return;
					}
					this.pageNo = rs.data.pageNo;
					this.pageCount = rs.data.pageCount;
					if (this.pageNo < this.pageCount) {
						this.finished = false;
					} else {
						this.finished = true;
						if (this.list.length > 0) {
							this.finishtext = '没有更多了';
						
						} else {
							this.finishtext = '暂无数据';
						}
					}
				},
				
			
			}
		}
</script>

<style lang="less" scoped="scoped">
	.page{
		min-height: 100vh;
		padding: 5px 0;
	}
</style>
